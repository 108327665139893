import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorService } from 'src/app/shared/layouts';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

    constructor(
        private readonly errorService: ErrorService,
        private readonly router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const errorMessage = error.error?.Message || 'Something went wrong; please try again later.';
                const errorTitle = error.error?.Title || 'Error';
                const errorStatusText = error.error?.statusText || 'Internal Server Error';
                const errorDetails = error.error?.Details || '';

                const errorResponse = new HttpErrorResponse({
                    error: {
                        Message: errorMessage,
                        Title: errorTitle,
                        Details: errorDetails
                    },
                    statusText: errorStatusText,
                    status: error.status,
                });

                if (error.status === 401) {
                    this.router.navigate(['/login'], { queryParams: { error: 'access-denied' } });
                } else if (error.status === 404) {
                    this.errorService.hasError = true;
                    this.errorService.errorDetails = errorResponse;
                } else {
                    this.errorService.hasError = true;
                    this.errorService.errorDetails = errorResponse;
                }
                return throwError(() => errorResponse);
            })
        );
    }
}
