<sh-card style="height: 60vh" label="Overview of subscribed systems">
  <sh-spinner label='Loading Subscibed SLPs' *ngIf="isLoading"></sh-spinner>
  <sh-table *ngIf="!isLoading">
    <sh-table-row slot="header">
      <sh-table-head columns='6' col-m='8' col-s='10' (toggle)="sortData('BUISNESSLINE')">Business Line</sh-table-head>
      <sh-table-head columns='6' col-m='8' col-s='10' (toggle)="sortData('SLPNAME')">SLP Name</sh-table-head>
      <!-- <sh-table-head columns='4' col-m='4' col-s='2' (toggle)="sortData('CSPROJECTMANAGER')">Project Manager</sh-table-head> -->
      <sh-table-head columns='4' col-m='2' col-s='2' (toggle)="sortData('DUEDATE')">Due Date</sh-table-head>
      <sh-table-head columns='4' col-m='2' col-s='1' (toggle)="sortData('STATUS')">Status</sh-table-head>
      <sh-table-head columns='4' col-m='2' col-s='1'>Action</sh-table-head>
    </sh-table-row>

    <sh-table-row button *ngFor="let slp of subscribedSlpData" (click)="navigateToPage(slp)">
      <sh-table-cell columns='6' col-m='8' col-s='10'>{{
        slp["BUISNESSLINE"]
        }}</sh-table-cell>
      <sh-table-cell columns='6' col-m='8' col-s='10'>{{ slp["SLPNAME"] }}</sh-table-cell>
      <!-- <sh-table-cell columns='4' col-m='4' col-s='2'>{{
        slp["CSPROJECTMANAGER"]
        }}</sh-table-cell> -->
      <sh-table-cell columns='4' col-m='2' col-s='2'>{{ slp['DUEDATE'] | date:'yyyy-MM-dd'}}</sh-table-cell>
      <sh-table-cell columns='4' col-m='2' col-s='1'>{{ slp["STATUS"] }}</sh-table-cell>
      <sh-table-cell columns='4' col-m='2' col-s='2'><sh-button size='auto' color="secondary" label='Unsubscribe' (click)="unsubscribe(slp); $event.stopPropagation()" style="margin-top:5px;"></sh-button></sh-table-cell>
    </sh-table-row>
  </sh-table>
  <sh-empty-state icon='studies-reject' label='No projects are subscribed'
    *ngIf="!isLoading && subscribedSlpData.length === 0"></sh-empty-state>
</sh-card>