<form [formGroup]="SlpProjectDataForm" style="display: flex; height: 100%; width: 100%;">
    <sh-grid>
        <sh-card columns="6" col-m='12' col-s='12'>
            <div style="display: flex; flex-direction: column; margin-top: 30px;">
                <div style="display: flex; flex-direction: row; margin-bottom: 20px; justify-content: space-between;">
                    <div style="width: 27%;">
                        <sh-dropdown label='Select Business Line' [value]='slpbusinessline' style="width: 100%;"
                            formControlName="BUISNESSLINE" ngDefaultControl mandatory [attr.readonly]="isReadOnly ? true : null">
                            <sh-menu-item *ngFor="let businessLine of businessLineArray" label="{{businessLine}}"
                                (click)="updateSelectedBusinessLine(businessLine)"></sh-menu-item>
                        </sh-dropdown>
                        <small
                            *ngIf="SlpProjectDataForm.get('BUISNESSLINE')?.touched && SlpProjectDataForm.get('BUISNESSLINE')?.invalid"><sup>*</sup>
                            Business Line is required field.</small>
                    </div>

                    <div style="width: 40%;">
                        <sh-input-text label="SLP Name" id="slp-name" style="width: 100%;" formControlName="SLPNAME"
                            ngDefaultControl no-clear mandatory [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                        <small
                            *ngIf="SlpProjectDataForm.get('SLPNAME')?.touched && SlpProjectDataForm.get('SLPNAME')?.invalid"><sup>*</sup>SLP
                            Name is required field.</small>
                    </div>

                    <sh-dropdown label="Status" style="width: 27%;" [value]="slpStatus" formControlName="STATUS"
                        ngDefaultControl mandatory [attr.readonly]="isReadOnly ? true : null">
                        <sh-menu-item *ngFor="let status of slpStatusArray" label="{{status}}"
                            (click)="updateStatus(status)">
                        </sh-menu-item>
                    </sh-dropdown>
                </div>

                <div style="display: flex; padding-bottom: 10px; justify-content: space-between; width: 100%;">
                    <div style="display: flex; width: 27%;"></div>
                    <div style="display: flex; flex-direction: column; margin-bottom: 20px; width: 70%; height: 100px;">
                        <!-- <div style="margin-bottom: 20px;">
                            <sh-input-text label="SLP CS Project Manager" id="cs-ProjectManager"
                                formControlName="CSPROJECTMANAGER" ngDefaultControl no-clear mandatory [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                            <small
                                *ngIf="SlpProjectDataForm.get('CSPROJECTMANAGER')?.touched && SlpProjectDataForm.get('CSPROJECTMANAGER')?.invalid"><sup>*</sup>SLP
                                CS Project Manager is required field.</small>
                        </div>
                        <div>
                            <sh-input-text label="SLP Project Expert" id="cs-Expert" formControlName="CSEXPERT"
                                ngDefaultControl no-clear mandatory [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                            <small
                                *ngIf="SlpProjectDataForm.get('CSEXPERT')?.touched && SlpProjectDataForm.get('CSEXPERT')?.invalid"><sup>*</sup>SLP
                                Project Expert is required field.</small>
                        </div> -->
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-between; margin-bottom: 20px;">
                <div style="width: 100%; margin-bottom: 10px;">
                    <sh-datepicker label="M300" id="due-Date" type="date" format="YYYYMMDD" single separator="-"
                        style="margin-bottom: 10px; width: 27%;" formControlName="DUEDATE"
                        (value-changed)="dateChange($event)" ngDefaultControl no-clear mandatory [attr.readonly]="isReadOnly ? true : null"></sh-datepicker>
                </div>

                <div style="display: flex; flex-direction: column; width: 100%;">
                    <div style="display: flex; flex-direction: row; margin-bottom: 20px">
                        <sh-toggle label='SLP Necessary' (click)="updateIsNecessary()" [active]="isNecessary"
                            style="width: 27%;" formControlName="SLPNECESSARY" ngDefaultControl></sh-toggle>
                        <div style="width: 70%; margin-left: 3%">
                            <sh-input-text label='Reason(When not Relevant)' no-clear
                                formControlName="SLPNECESSARYREASONDATA" ngDefaultControl
                                [mandatory]="!isNecessary" [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                            <small
                                *ngIf="!isNecessary && SlpProjectDataForm.get('SLPNECESSARYREASONDATA')?.touched && SlpProjectDataForm.get('SLPNECESSARYREASONDATA')?.invalid"><sup>*</sup>Necessary
                                Reason Data is required field.</small>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-bottom: 20px">
                        <sh-toggle label='Monitoring' (click)="updateIsMonitoring()" [active]="isMonitoring"
                            style="width: 27%;" formControlName="ISMONITORINGNECESSARY" ngDefaultControl></sh-toggle>
                        <div style="width: 70%; margin-left: 3%">
                            <sh-input-text label='Reason (When not Relevant)' no-clear
                                formControlName="MONITORINGREASONDATA" ngDefaultControl
                                [mandatory]="!isMonitoring" [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                            <small
                                *ngIf="!isMonitoring && SlpProjectDataForm.get('MONITORINGREASONDATA')?.touched && SlpProjectDataForm.get('MONITORINGREASONDATA')?.invalid"><sup>*</sup>Monitoring
                                Reason Data is required field.</small>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <sh-input-text textarea rows='10' label='Project Description' value=''
                    formControlName="PROJECTDESCRIPTION" ngDefaultControl [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
            </div>
        </sh-card>

        <!-- 2nd card -->
        <div columns="6" col-m='12' col-s='12' style="display: flex; flex-direction: column;">
            <!-- Target System ande Base system Details -->
            <sh-card label="Data" style="height: 60%;">
                <div style="display: flex; flex-direction: row; width: 100%;">
                    <div style="margin-left:10px; width: 48%;">
                        <sh-text size="header-2" style="width: 97%;">Base Systems</sh-text>
                        <div class="dropdown">
                            <div class="search-box" [ngClass]="isReadOnly ? 'readOnly' : '' " >
                                <sh-icon *ngIf= "!isReadOnly" color="grey" icon="search" size="xs" style="width: 10%;"></sh-icon>
                                <div class="input-wrapper">
                                    <input type="text" id='base-system-search-input' #baseSystemSearchInputRef
                                        class="boxCSS" (input)="filterBaseSystemSearchResults($event)"
                                        (focus)="showDropDown()" placeholder="Search Systems..."
                                        formControlName="BASESYSTEMS" ngDefaultControl>
                                    <sup class="required-indicator">*</sup>
                                </div>
                                <sh-icon *ngIf= "!isReadOnly" color="#EC6602" icon="cancel" size="s" button (click)="hideDropDown()"
                                    style="width: 10%;"></sh-icon>
                            </div>
                            <div class="dropdown-content" [class.show]="isBaseSystemSearchDropDownOpen">
                                <sh-spinner label='Loading' *ngIf="baseSystemDataArray.length === 0"></sh-spinner>
                                <label *ngFor="let baseSystem of filteredBaseSystemDataArray"
                                    (click)="onBaseSystemSelection(baseSystem)">
                                    {{baseSystem['MATTEXT']}} - {{baseSystem['MATID']}}
                                </label>
                            </div>
                        </div>
                        <small
                            *ngIf="SlpProjectDataForm.get('BASESYSTEMS')?.touched && SlpProjectDataForm.get('BASESYSTEMS')?.invalid"><sup>*</sup>
                            Atlease one base system must be selected.</small>
                    </div>
                    <div style="margin-left:10px; width: 50%;">
                        <sh-text size="header-2" style="width: 97%;">Select Year</sh-text>
                        <sh-dropdown label="Select Years" multi-select id='years-dropdown' #yearsDropdownRef
                            formControlName="YEARS" ngDefaultControl mandatory [attr.readonly]="isReadOnly ? true : null">
                            <sh-menu-item *ngFor="let yearObject of yearsList; index as i" checkbox
                                (click)="onSelectYearCheckboxClick(i)" label="{{yearObject['years']}}"
                                [active]="yearObject['isSelected']"></sh-menu-item>
                        </sh-dropdown>
                        <small
                            *ngIf="SlpProjectDataForm.get('YEARS')?.touched && SlpProjectDataForm.get('YEARS')?.invalid"><sup>*</sup>
                            Atlease one year must be selected.</small>
                    </div>
                </div>
                <sh-text size="header-2" style="margin-left:10px; width: 97%;"
                    *ngIf="selectedBaseSystemsArray.length !== 0">Selected Base Systems</sh-text>
                <div style="display: flex; flex-wrap: wrap; margin-left: 10px;">
                    <div *ngFor="let baseSystemData of selectedBaseSystemsArray; let i = index"
                        class="base-system-details">

                        <sh-text size="header-2" class="base-system-name"
                            id={{baseSystemData.MATID}}>{{baseSystemData.MATTEXT}}
                            - {{baseSystemData.MATID}}</sh-text>
                        <sh-tooltip label="{{baseSystemData.MATTEXT}} - {{baseSystemData.MATID}}"
                            target={{baseSystemData.MATID}} placement='top' variation='short'></sh-tooltip>
                        <sh-icon *ngIf= "!isReadOnly" color="#EC6602" icon="cancel" size="s" button
                            (click)="removeSelectedBaseSystem(baseSystemData)"></sh-icon>

                    </div>
                </div>
                <div style="display: flex; flex-direction: row; width: 100%;">
                    <div style="margin-left:10px; width: 48%;">
                        <sh-text size="header-2" style="width: 97%;">Select Notification Categories</sh-text>
                        <sh-dropdown label="Select Notification Categories" multi-select
                            id='notification-categories-dropdown' #notificationCategoriesDropdownRef
                            formControlName="NOTIFICATIONCATEGORY" ngDefaultControl mandatory [attr.readonly]="isReadOnly ? true : null">
                            <sh-checkbox label="Select All" (click)="onSelectAllNotificationCategoryClick()"
                                [active]="isAllStNotificationCategorySelected">
                            </sh-checkbox>
                            <sh-menu-item *ngFor="let notifictionCategory of notificationCategoriesArray; index as i"
                                checkbox (click)="onSelectNotificationCategoryClick(i)"
                                label="{{notifictionCategory['notiCategoryId']}} - {{notifictionCategory['notiCategoryText']}}"
                                [active]="notifictionCategory['isSelected']"></sh-menu-item>
                        </sh-dropdown>
                        <small
                            *ngIf="SlpProjectDataForm.get('NOTIFICATIONCATEGORY')?.touched && SlpProjectDataForm.get('NOTIFICATIONCATEGORY')?.invalid"><sup>*</sup>
                            Atlease one notification category must be selected.</small>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 10px;">
                    <sh-text size="header-2" style="margin-bottom:10px;">Target
                        Systems</sh-text>
                    <form [formGroup]="TargetSystemDataForm"  [ngClass]="{'disabled-form': TargetSystemDataForm.disabled}">
                        <ng-container formArrayName="TARGETSYSTEMDATARRAY"
                            style="display: flex; flex-direction: column; padding-left: 10px;">
                            <div *ngFor="let data of getTargetFieldsFormInfo.controls; let i = index"
                                style="display: flex; flex-direction: column; width: 97%;">
                                <div style="display: flex; flex-direction: row; width: 97%; margin-bottom: 10px;">
                                    <div [formGroupName]="i" style="display: flex; flex-direction: row; width: 90%;">
                                        <sh-input-text label="SLP System IVK" style="width: 48%;"
                                            formControlName="SLPSYSTEMIVK" ngDefaultControl no-clear [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                                        <sh-input-text label="SLP System Name" style=" padding-left: 4%; width: 48%;"
                                            formControlName="SLPSYSTEMNAME" ngDefaultControl no-clear [attr.readonly]="isReadOnly ? true : null"></sh-input-text>
                                    </div>

                                    <div style="display: flex; width: 10%;"
                                        *ngIf="getTargetFieldsFormInfo && i == getTargetFieldsFormInfo.length - 1">
                                        <div style="display: flex; padding-top: 4px; padding-left: 2%; width: 48%;">
                                            <sh-icon *ngIf= "!isReadOnly" color="#EC6602" icon="plus" size="m" button
                                                (click)="addNewTargetField()"></sh-icon>
                                        </div>

                                        <div style="display: flex; padding-top: 4px; padding-left: 2%; width: 48%;"
                                            *ngIf="getTargetFieldsFormInfo && getTargetFieldsFormInfo.length > 1">
                                            <sh-icon *ngIf= "!isReadOnly" color="#EC6602" icon="minus" size="m" button
                                                (click)="removeTargetField(getTargetFieldsFormInfo.length-1)"></sh-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </form>
                </div>

            </sh-card>

            <!-- year -->
            <sh-card columns="6" col-m='12' col-s='12' style="margin-top: 2%; height: 38%;">
                <form [formGroup]="CostTableForm"  [ngClass]="{'disabled-form': CostTableForm.disabled}">
                    <table id="slpFormDataTable" columns="12" col-m="12" col-s="12" style="width: 97%;">
                        <thead>
                            <tr>
                                <th rowspan="2.5"></th>
                                <th rowspan="2.0">Release</th>
                                <th rowspan="1.5">Year 1</th>
                                <th rowspan="1.5">Year 2</th>
                                <th rowspan="1.5">Year 3</th>
                                <th rowspan="1.5">Year 4</th>
                                <th rowspan="1.5">Year 5</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> IB </td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBRelease"
                                        (keydown)="preventDecimalInput($event)" ngDefaultControl></td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBYear1" ngDefaultControl
                                        (keydown)="preventDecimalInput($event)" (input)="handelCostTableFormValidation('IBYear', '1')"></td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBYear2" ngDefaultControl
                                        (keydown)="preventDecimalInput($event)" (input)="handelCostTableFormValidation('IBYear', '2')"></td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBYear3" ngDefaultControl
                                        (keydown)="preventDecimalInput($event)" (input)="handelCostTableFormValidation('IBYear', '3')"></td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBYear4" ngDefaultControl
                                        (keydown)="preventDecimalInput($event)" (input)="handelCostTableFormValidation('IBYear', '4')"></td>
                                <td> <input type="number" class="small-input no-arrows" formControlName="IBYear5"
                                        (keydown)="preventDecimalInput($event)" ngDefaultControl>
                                </td>
                            </tr>
                            <tr>
                                <td> Hourly Rate (€)</td>
                                <td>
                                    <div><input type="number" class="small-input no-arrows" formControlName="HourlyRateRelease"
                                            (keydown)="preventNegativeInput($event)" ngDefaultControl required>
                                        <sup class="required-indicator">*</sup>
                                    </div>
                                </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="HourlyRateYear1"
                                        (keydown)="preventNegativeInput($event)" ngDefaultControl
                                        (input)="handelCostTableFormValidation('HourlyRateYear', '1')">
                                </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="HourlyRateYear2"
                                        (keydown)="preventNegativeInput($event)" ngDefaultControl
                                        (input)="handelCostTableFormValidation('HourlyRateYear', '2')">
                                </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="HourlyRateYear3"
                                        (keydown)="preventNegativeInput($event)" ngDefaultControl
                                        (input)="handelCostTableFormValidation('HourlyRateYear', '3')">
                                </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="HourlyRateYear4"
                                        (keydown)="preventNegativeInput($event)" ngDefaultControl
                                        (input)="handelCostTableFormValidation('HourlyRateYear', '4')">
                                </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="HourlyRateYear5"
                                        (keydown)="preventNegativeInput($event)" ngDefaultControl></td>
                            </tr>
                            <tr>
                                <td id="PartCostChanges"> Part Cost Changes(%)
                                    <sh-tooltip label="Part Cost Changes" target="PartCostChanges" placement='top'
                                        variation='short'></sh-tooltip>
                                </td>
                                <td> </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="PartCostIncreaseYear1" ngDefaultControl
                                    (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('PartCostIncreaseYear', '1')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="PartCostIncreaseYear2" ngDefaultControl
                                        (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('PartCostIncreaseYear', '2')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="PartCostIncreaseYear3" ngDefaultControl
                                        (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('PartCostIncreaseYear', '3')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="PartCostIncreaseYear4" ngDefaultControl
                                        (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('PartCostIncreaseYear', '4')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="PartCostIncreaseYear5"
                                        (keydown)="preventScientificInput($event)" ngDefaultControl></td>
                            </tr>
                            <tr>
                                <td id="GeneralCostChanges"> General Cost Changes(%)
                                    <sh-tooltip label="General Cost Changes" target="GeneralCostChanges" placement='top'
                                        variation='short'></sh-tooltip>
                                </td>
                        
                                <td> </td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="ProductivityMeasureYear1"
                                        ngDefaultControl (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('ProductivityMeasureYear', '1')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="ProductivityMeasureYear2"
                                        ngDefaultControl (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('ProductivityMeasureYear', '2')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="ProductivityMeasureYear3"
                                        ngDefaultControl v (input)="handelCostTableFormValidation('ProductivityMeasureYear', '3')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="ProductivityMeasureYear4"
                                        ngDefaultControl (keydown)="preventScientificInput($event)"
                                        (input)="handelCostTableFormValidation('ProductivityMeasureYear', '4')"></td>
                                <td> <input type="text" class="small-input no-arrows" formControlName="ProductivityMeasureYear5"
                                        (keydown)="preventScientificInput($event)" ngDefaultControl></td>
                            </tr>
                        </tbody>
                    </table>
                    <small
                        *ngIf="CostTableForm.get('HourlyRateRelease')?.touched && CostTableForm.get('HourlyRateRelease')?.invalid">Hourly
                        Release Rate is required field.</small>
                </form>
            </sh-card>
        </div>
    </sh-grid>
</form>