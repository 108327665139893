import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateSlpFormService {

  constructor() { }

  private allIvksList: Set<string> = new Set<string>()

  getAllIvksList(){
    return this.allIvksList;
  }

  updateAllIvksList(ivksList: Set<string>){
    this.allIvksList = ivksList;
  }
}
