import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  constructor() {}

  transform(
    value: any,
    decimalDigits?: number,
    locale?: string
  ): string | null {
    let result;
    decimalDigits = decimalDigits ?? 2;
    locale = locale ?? 'en-US';
    try {
      result = Number(value).toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalDigits,
      });
    } catch {
      result = value;
    }
    return result;
  }
}
