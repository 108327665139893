import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { SqlServiceService } from 'src/app/core/sql-service.service';
import { RoleService } from 'src/app/services/role.service';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';
import {
  SlpDetailsCard,
  BaseSystemDataCard,
  FunctionalGroup,
  FunctionalGroupDataCard,
  FunctionalGroupData,
} from 'src/app/models';
import { Constants } from 'src/app/constants';
import { CreateSlpFormService } from '../../create-slp-form/create-slp-form.service';

@Component({
  selector: 'app-base-systems-form',
  templateUrl: './base-systems-form2.component.html',
  styleUrls: ['./base-systems-form2.component.scss'],
})
export class BaseSystemsFormComponent {
  @Input() slpDetailsCardData: SlpDetailsCard;
  @Input() isreferenceSystemDisplay: boolean;
  @Input() selectedBaseSystemDetailsDataArray: BaseSystemDataCard[];
  @Input() savedSelectedBaseSystemCardId: number;
  @Input() savedSelectedFGs: FunctionalGroup[];
  @Input() isreloadclicked: boolean;
  @Input() isNewSlp: boolean;
  @Input() savedFgDataCache: Map<string, any[]>;
  @Input() savedSumIBDataCache: Map<string, number>;
  @Output() isReferenceSystemSelected = new EventEmitter<boolean>();
  @ViewChild(SearchBarComponent) searchBarComponent: SearchBarComponent;

  public readonly FgSearchKeys = Constants.FG_SEARCH_KEY;
  isReadOnly: boolean = false;
  isLoading: boolean = false;
  selectedBaseSystemCardId: number = -1;
  FGLoadingSpinner: boolean = false;
  slpHourRate: number = 0;
  existingSlpID: number = 0;
  totaltotalcost: number = 0;
  sumIB: number = 0;
  fgDataCache = new Map<string, any[]>();
  sumIBDataCache = new Map<string, number>();
  tempFGArray: any[] = [];
  slpName: string;
  slpYears: string;
  reloadClicked: boolean = false;
  slpNotifications: string;
  sumSPQuantity: number = 0;
  sumConfirmTravelShare: number = 0;
  sumSPPriceQty: number = 0;
  sumConfirmWorkShare: number = 0;
  FGArray: any[] = [];
  filteredFGArray: any[] = [];
  selectedSPCount = 0;
  selectedFGs: FunctionalGroup[] = [];
  costpersystem: number = 0;
  referenceSystemData: { [key: string]: FunctionalGroupData[] } = {};

  allIVKList: Set<string> = new Set<string>();

  constructor(
    private sqlServiceService: SqlServiceService,
    private roleService: RoleService,
    private readonly createSlpFormService: CreateSlpFormService
  ) {
    this.isreferenceSystemDisplay = false;
  }

  ngOnInit(): void {
    this.isreferenceSystemDisplay = true;
    this.setPermissions();
    this.setSavedData();
    this.loadBaseSystemData();
    this.getSavedCheckboxandSelectedPartsCalculationData();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isreferenceSystemDisplay = false;
  }

  setPermissions() {
    this.isReadOnly = !this.roleService.isDeveloperOrProjectManager();
  }

  setSavedData() {
    this.slpHourRate = this.slpDetailsCardData.hourRate;
    this.slpName = this.slpDetailsCardData.projectName;
    this.slpYears = this.slpDetailsCardData.years;
    this.existingSlpID = this.slpDetailsCardData.slpId;
    this.slpNotifications = this.transformNotificationString(this.slpDetailsCardData.notifications);
    this.selectedFGs = this.savedSelectedFGs;
    this.reloadClicked = this.isreloadclicked;
    this.fgDataCache = this.savedFgDataCache;
    this.sumIBDataCache = this.savedSumIBDataCache;
  }

  async loadBaseSystemData() {
    this.isLoading = true;

    for (const [idx, baseSystemData] of this.selectedBaseSystemDetailsDataArray.entries()) {
      const matid = baseSystemData.MATID;
      this.tempFGArray = [];
      this.totaltotalcost = 0;

      try {
        await this.setSumIbData(matid);
        await this.executeBaseSystemDataApiCall(matid, idx);
      } catch (error) {
        this.isLoading = false;
      }
    }
    this.createSlpFormService.updateAllIvksList(this.allIVKList);
    this.isLoading = false;
    if (this.selectedBaseSystemDetailsDataArray.length > 0) {
      this.selectedBaseSystemDetailsDataArray.forEach((baseSystem) => {
        baseSystem.isSelected = false;
      });
      this.selectedBaseSystemCardId = 0;
      this.toggleClick(this.selectedBaseSystemCardId);
      const matid = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
      this.FGArray = this.fgDataCache.get(matid) ?? [];
      this.filteredFGArray = this.FGArray;
    }
  }

  async getSavedCheckboxandSelectedPartsCalculationData() {
    this.isReferenceSystemSelected.emit(false);
    if (this.reloadClicked || this.existingSlpID !== 0) {
      this.selectedFGs = await lastValueFrom(
        this.sqlServiceService.getCheckedDataForSLP(this.existingSlpID)
      );
      this.referenceSystemData = await lastValueFrom(
        this.sqlServiceService.FetchReferenceSystemStoredDataForSLP(
          this.selectedFGs,
          this.existingSlpID
        )
      );
    }
    if (this.selectedFGs.length > 0) {
      this.isReferenceSystemSelected.emit(true);
    }
  }

  transformNotificationString(input: string): string {
    return input
      .split(',')
      .map((item) => item.trim())
      .map((item) => item.split(' ')[0])
      .join(',');
  }

  toggleClick(cardId: number): void {
    if (this.selectedBaseSystemCardId !== -1) {
      this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId]['isSelected'] = false;
    }
    this.selectedBaseSystemCardId = cardId;
    this.selectedBaseSystemDetailsDataArray[cardId]['isSelected'] = true;
    this.searchBarComponent?.clearSearch();
  }

  updateSelectedBaseSystemCardCost(cardId: number): void {
    this.selectedBaseSystemDetailsDataArray[cardId]['costPerSystem'] = this.costpersystem;
  }

  async processServicePartsData(
    servicePartsDataArray: any,
    matid: string,
    id: number
  ) {
    if (servicePartsDataArray.AGGREGATEDRESULTS.length > 0) {
      this.tempFGArray = this.tempFGArray.concat(servicePartsDataArray.AGGREGATEDRESULTS);
      this.totaltotalcost = servicePartsDataArray.TOTALCOSTSUM;
    }
    await this.OthersCalculation(matid);

    this.costpersystem = this.sumIB > 0 ? this.totaltotalcost / this.sumIB : 0;
    this.updateSelectedBaseSystemCardCost(id);
    this.fgDataCache.set(matid, this.tempFGArray);
    this.FGArray = this.tempFGArray;
    this.filteredFGArray = this.FGArray;
    this.FGLoadingSpinner = false;
    this.reloadClicked = false;
  }

  async executeBaseSystemDataApiCall(matid: string, id: number) {
    if (this.reloadClicked) {
      this.fgDataCache.clear();
    }
    if (this.fgDataCache.has(matid)) {
      this.FGArray = this.fgDataCache.get(matid) ?? [];
      this.filteredFGArray = this.FGArray;
    } else if (this.isNewSlp && !this.reloadClicked) {
      const servicePartsDataArray = await lastValueFrom(
        this.sqlServiceService.getServicePartData(
          matid,
          this.existingSlpID,
          this.slpHourRate,
          matid,
          this.slpYears,
          this.slpNotifications
        )
      );
      await this.processServicePartsData(servicePartsDataArray, matid, id);
    } else {
      this.tempFGArray = [];
      const servicePartsDataArray = await lastValueFrom(
        this.sqlServiceService.getStoredProcedureFGdata(this.existingSlpID, matid)
      );
      await this.processServicePartsData(servicePartsDataArray, matid, id);
    }

    this.filteredFGArray.forEach((fg) => {
      if (!this.allIVKList.has(fg.IVK)) {
        this.allIVKList.add(fg.IVK);
      }
    });
  }

  async setSumIbData(matid: string) {
    if (this.reloadClicked && this.existingSlpID !== 0) {
      this.sumIBDataCache.clear();
    }

    if (this.sumIBDataCache.has(matid)) {
      this.sumIB = this.sumIBDataCache.get(matid) ?? 0;
    } else {
      this.sumIB = await lastValueFrom(this.sqlServiceService.getSumIB(matid));
      this.sumIBDataCache.set(matid, this.sumIB);
    }
  }

  async calculateSparePartTableData(selectedCardData: { matid: string; id: number }) {
    this.FGLoadingSpinner = true;
    this.tempFGArray = [];
    this.FGArray = [];
    this.filteredFGArray = this.FGArray;
    const { matid, id } = selectedCardData;

    // Calculate logic
    this.toggleClick(id);
    this.totaltotalcost = 0;

    await this.setSumIbData(matid);
    await this.executeBaseSystemDataApiCall(matid, id);

    this.FGLoadingSpinner = false;
  }

  async OthersCalculation(matid: string) {
    let otherPartsDataArray: any = [];
    if (this.isNewSlp) {
      otherPartsDataArray = await lastValueFrom(
        this.sqlServiceService.getServicePartOthers(
          matid,
          this.existingSlpID,
          this.slpHourRate,
          matid,
          this.slpYears,
          this.slpNotifications
        )
      );
    } else {
      // when reload is clicked or when slpID is not 0
      otherPartsDataArray = await lastValueFrom(
        this.sqlServiceService.getStoredProcedureOthersData(this.existingSlpID, matid)
      );
    }
    this.tempFGArray = [
      ...this.tempFGArray,
      ...otherPartsDataArray.AGGREGATEDRESULTSNOPART,
      ...otherPartsDataArray.AGGREGATEDRESULTSNOPARTORTRAVEL,
      ...otherPartsDataArray.AGGREGATEDRESULTSOTHERS,
    ];
    this.totaltotalcost = this.totaltotalcost ? this.totaltotalcost : otherPartsDataArray.TOTALCOSTSUM;
    this.sumIB = this.tempFGArray.length > 0 ? this.tempFGArray[0].SYSTEMIB : 0;

    this.tempFGArray.forEach(fg => {
      if(!this.allIVKList.has(fg.IVK)){
        this.allIVKList.add(fg.IVK);
      }
    });
  }

  // Handling Table Selection and Dselection
  isSameAsPrevious(index: number): boolean {
    if (index === 0) return false;
    return this.FGArray[index].FG === this.FGArray[index - 1].FG;
  }

  handleFGCheckboxSelection(fgData: FunctionalGroup) {
    if (this.isFGSelected(fgData.FG)) {
      this.deselectFG(fgData.FG);
    } else {
      this.selectFG(fgData.FG);
    }
  }

  isFGSelected(fgName: string): boolean {
    return this.FGArray.some((FGData) => FGData.FG === fgName && FGData.CHECKED === true);
  }

  deselectFG(fgName: string) {
    const matId = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
    this.selectedFGs = this.selectedFGs.filter(
      (obj) => !(obj.FG === fgName && obj.SYSMATID === matId)
    );
    this.FGArray.forEach((item) => {
      if (item.FG === fgName) {
        item.CHECKED = false;
      }
    });
    this.fgDataCache.set(matId, this.FGArray);
    if (this.selectedFGs.length === 0) {
      this.isReferenceSystemSelected.emit(false);
    }
  }

  selectFG(fgName: string) {
    const matId = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
    this.FGArray.forEach((item) => {
      if (item.FG === fgName) {
        item.CHECKED = true;
        this.selectedFGs.push(item);
        this.isReferenceSystemSelected.emit(true);
      }
    });
    this.fgDataCache.set(matId, this.FGArray);
  }

  // Method to handle SP checkbox selection
  handleSPCheckboxSelection(obj: FunctionalGroup) {
    if (obj.CHECKED === true) {
      this.deselectSP(obj.IVK, obj.SYSMATID, obj.MATERIAL);
    } else {
      this.selectedFGs.push(obj);
      this.isReferenceSystemSelected.emit(true);
    }
    obj.CHECKED = !obj.CHECKED;
  }

  // Method to deselect a row ID
  deselectSP(fgIVK: string, matId: string, fgName: string) {
    this.selectedFGs = this.selectedFGs.filter(
      (obj) => !(obj.IVK === fgIVK && obj.SYSMATID === matId && obj.MATERIAL == fgName)
    );
    if (this.selectedFGs.length === 0) {
      this.isReferenceSystemSelected.emit(false);
    }
  }

  getUniqueFGValues() {
    let updateSelectedFGArray: FunctionalGroupDataCard[] = [];
    const seen = new Set();
    const filteredSampleArray = this.selectedFGs.filter((item: any) => {
      const val = item['FG'];
      if (seen.has(val)) {
        return false;
      } else {
        seen.add(val);
        return true;
      }
    });
    filteredSampleArray.forEach((selectedSparePartsData, index) => {
      updateSelectedFGArray.push({
        ...selectedSparePartsData,
        isSelected: false,
        cardId: index,
        TARGETCOST: 0,
        REFCOST: 0,
      });
    });
    return updateSelectedFGArray;
  }

  getAllFGValues() {
    let updateSelectedFGArray: FunctionalGroupDataCard[] = [];
    this.selectedFGs.forEach((selectedSparePartsData, index) => {
      updateSelectedFGArray.push({
        ...selectedSparePartsData,
        isSelected: false,
        cardId: index,
        TARGETCOST: 0,
        REFCOST: 0,
      });
    });
    return updateSelectedFGArray;
  }

  handleSearchResults(results: any[]): void {
    this.filteredFGArray = results;
  }
}
