<div class="selectionCard" *ngIf='baseSystemCard'
    (click)="onBaseSystemCardClick(baseSystemCard['MATID'], baseSystemCardId)"
    [ngClass]="{'selected-card': baseSystemCard['isSelected']}">
    <div style="display: flex; justify-content: space-between; height: 30%;">
        <sh-text size="header-1">{{baseSystemCard['MATTEXT']}}</sh-text>
    </div>
    <div style="display: flex; overflow-y: hidden; width: 100%; height: 50%;">
        <div style="display: flex; flex-direction: column; width: 50%;">
            <small>Material</small>
            <sh-text>{{ baseSystemCard["MATID"] }}</sh-text>
        </div>
        <div style="display: flex; flex-direction: column; width: 50%;">
            <small>Cost per system</small>
            <sh-text>{{ baseSystemCard["costPerSystem"] | formatNumber}}</sh-text>
        </div>
    </div>
</div>
 
 
<div class="selectionCard" *ngIf='selectedFGcard'
    (click)="onFGCardClick(selectedFGcard['FG'], selectedFGcard['cardId'])"
    [ngClass]="{'selected-card': isSelected && selectedFGcard['isSelected']}">
    <div class="card-title">
        <sh-text size="header-1">{{selectedFGcard['FG']}}</sh-text>
    </div>
    <div class="card-details">
        <!-- <div class="card-details-col">
            <small>IB</small>
            <sh-text>300</sh-text>
        </div> -->
        <div class="card-details-col">
            <small>FG Cost Reference</small>
            <sh-text>{{selectedFGcard['REFCOST'] | formatNumber}}</sh-text>
        </div>
        <div class="card-details-col">
            <small>FG Cost Target</small>
            <sh-text>{{selectedFGcard['TARGETCOST'] | formatNumber}}</sh-text>
        </div>
    </div>
</div>
 