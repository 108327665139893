<div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-bar">
        <sh-spinner label='Reloading Data' *ngIf="isLoading"></sh-spinner>
    </div>
</div>

<div *ngIf="isReloadModalVisible" class="custom-dialog">
    <div class="custom-dialog-content">
        <h2>Success</h2>
        <p>Data reloaded successfully</p>
        <button type="button" (click)="closeSuccessDialog()">OK</button>
    </div>
</div>

<sh-stepper [value]="currentStep" slot="header" id="demo-stepper-2" class="create-slp-stepper" readonly>
    <sh-stepper-item label="SLP Project Data"
        active="true"></sh-stepper-item>
    <sh-stepper-item label="Base Systems"></sh-stepper-item>
    <sh-stepper-item label="Reference/Target Systems"></sh-stepper-item>
</sh-stepper>

<div class="create-slp-form-page">
    <!-- First Stepper -->
    <app-slp-project-data-form *ngIf="currentStep === 1" (isFormInvalid)="onFormValidityChange($event)"
        (hourlyRateValue)="onHourlyRateUpdated($event)"
        (slpFormFieldsForStoreProcedurevalue)="onSlpFormValuesUsedForStoreProcedureUpdated($event)"
        [savedSlpFormData]="savedSlpFormData" [savedTargetSystemFormData]="savedTargetSystemFormData"
        [savedSlpCostTableData]="savedSlpCostTableData"
        [selectedBaseSystemDetailsDataArray]="selectedBaseSystemDetailsDataArray"
        [responseSlpCostTableData]="responseSlpCostTableData"
        [savedNotificationCategoriesArray]="savedNotificationCategoriesArray" [savedYearList]="savedYearList"
        class="create-slp-form"></app-slp-project-data-form>

    <!-- Second Stepper -->
    <app-base-systems-form *ngIf="currentStep === 2" [slpDetailsCardData]='slpDetailsCardData'
        [isreloadclicked]='isreloadclicked' [isNewSlp]="isNewSlp"
        [selectedBaseSystemDetailsDataArray]='selectedBaseSystemDetailsDataArray' [savedSelectedFGs]='savedSelectedFGs'
        [savedFgDataCache]='savedFgDataCache' [savedSumIBDataCache]='savedSumIBDataCache'
        [savedSelectedBaseSystemCardId]='savedSelectedBaseSystemCardId'
        (isReferenceSystemSelected)='onReferenceSystemSelected($event)' class="create-slp-form"></app-base-systems-form>

    <!-- third stepper -->
    <app-referene-systems-form *ngIf="currentStep === 3" [slpDetailsCardData]='slpDetailsCardData'
        [selectedAllFGDataArray]='selectedAllFGDataArray' [selectedUniqueFGDataArray]='selectedUniqueFGDataArray'
        (updateCurrentStep)="onUpdateCurrentStep($event)"
        [savedSumIBDataCache]='savedSumIBDataCache'
        [savedReferenceSystemData]="savedReferenceSystemData" class="create-slp-form"></app-referene-systems-form>
</div>

<div *ngIf="showCreateSlpSuccessDialog" class="custom-success-dialog">
    <div class="custom-dialog-content">
        <h1>Success</h1>
        <p>Record(s) have been successfully added!</p>
        <button (click)="closeCreateSlpDialog()">OK</button>
    </div>
</div>

<div class="create-slp-footer">
    <sh-button slot="footer" *ngIf="currentStep !== 1" label="Save / Previous" color="secondary" size="max-content"
        (click)="onCreateSlpStepperPreviousClick()" class="create-slp-previous-button"></sh-button>
    <sh-button slot="footer" *ngIf="slpDetailsCardData.slpId !== 0 && currentStep === 1" label="Reload"
        (click)="onReloadClick();" [disabled]="isFormInvalid || isReadOnly" style="margin-right:10px;"></sh-button>
    <sh-button slot="footer" *ngIf="currentStep !== 3" label="Save / Next" size="max-content"
        (click)="onCreateSlpStepperNextClick();" [disabled]="isFormInvalid || isNextDisabled "></sh-button>
</div>