import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { SqlServiceService } from 'src/app/core/sql-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';
import {
  FunctionalGroup,
  FunctionalGroupDataCard,
  FunctionalGroupData,
  UserData,
  SlpDetailsCard,
} from 'src/app/models';
import { CreateSlpFormService } from '../../create-slp-form/create-slp-form.service';

@Component({
  selector: 'app-referene-systems-form',
  templateUrl: './referene-systems-form.component.html',
  styleUrls: ['./referene-systems-form.component.scss'],
})
export class RefereneSystemsFormComponent implements OnInit {
  @Input() slpDetailsCardData: SlpDetailsCard;
  @Input() selectedAllFGDataArray: FunctionalGroupDataCard[];
  @Input() selectedUniqueFGDataArray: FunctionalGroupDataCard[];
  @Input() savedSumIBDataCache: Map<string, number>;
  @Input() savedReferenceSystemData: { [key: string]: FunctionalGroupData[] } = {};
  @Output() updateCurrentStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(SearchBarComponent) searchBarComponent: SearchBarComponent;

  currentStep: number = 1;
  isReadOnly: boolean = false;
  fgForm: FormGroup;
  showerror = false;
  slpHourRate: number = 0;
  slpName: string = '';
  activeFGName: string = '';
  showFGDataCardId: number;
  isFunctionalGroupInputData: boolean = false;
  isReferenceSystemDataSubmitted: boolean = false;
  selectedFGPartsCardsId: number = -1;
  FGTableSpinner: boolean = false;
  showCreateSlpSuccessDialog: boolean = false;

  totalcostPerPartRef: number = 0;
  totalcostPerPartTarget: number = 0;
  displayAll = false;

  totalSystemCostRef: number = 0;
  totalSystemCostTar: number = 0;

  fgInfoArray: FunctionalGroupData[] = [];
  addedPartsInfoArray: FunctionalGroupData[] = [];
  filteredFGInfoArray: any[] = [];
  fgInfoArrayAll: FunctionalGroupData[] = [];

  isNewPartAdded: boolean = false;
  showSuccessDialog: boolean = false;

  onConfirmNextStep: 'previousPage' | 'showAll' | 'showCard' = 'showCard';
  isInvalidIVKError = false;
  allIVKList: Set<string>;

  fgPartsDetails: Map<string, FunctionalGroupData[]> = new Map<string, FunctionalGroupData[]>();

  updatedReferenceSystemData: FunctionalGroupData[] = [];
  isLoading = true;

  constructor(
    private sqlServiceService: SqlServiceService,
    public authService: AuthService,
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    private readonly createSlpFormService: CreateSlpFormService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.initializeForm();
    this.setSlpDetails();
    this.allIVKList = this.createSlpFormService.getAllIvksList();
    this.initializeFgPartsDetails();
    this.saveUpdatedFGToDB(this.updatedReferenceSystemData);
  }

  setPermissions() {
    this.isReadOnly = !this.roleService.isDeveloperOrProjectManager();
  }

  initializeForm(): void {
    this.fgForm = this.formBuilder.group({
      FGInfoArray: this.formBuilder.array([]),
    });
  }

  setSlpDetails(): void {
    this.slpHourRate = this.slpDetailsCardData.hourRate;
    this.slpName = this.slpDetailsCardData.projectName;
  }

  groupFgDetails() {
    let uniqueFGNames = new Set(this.selectedAllFGDataArray.map((fg) => fg.FG));
    let fgDetails = new Map<string, FunctionalGroupDataCard[]>();
    uniqueFGNames.forEach((uniqueFG) => {
      fgDetails.set(
        uniqueFG,
        this.selectedAllFGDataArray.filter((fg) => fg.FG === uniqueFG)
      );
    });
    return fgDetails;
  }

  initializeFgPartsDetails() {
    let groupedFgDetails = this.groupFgDetails();
    Array.from(groupedFgDetails.keys()).forEach((fgName) => {
      const uniqueFgDetails = this.handleDuplicateFG(groupedFgDetails.get(fgName));
      let calculatedFgDetails = this.updateCostCalculations(uniqueFgDetails, fgName);
      // Added Parts
      this.savedReferenceSystemData[fgName]
        ?.filter((obj) => obj.ADDEDPART == 1)
        .forEach((addedPart) => {
          calculatedFgDetails.push(addedPart);
        });

      calculatedFgDetails = this.updateInfluenceCalculations(calculatedFgDetails);
      this.updatedReferenceSystemData.push(...calculatedFgDetails);

      this.fgPartsDetails.set(fgName, calculatedFgDetails);
    });
  }

  handleDuplicateFG(fgDataArray?: FunctionalGroupDataCard[]) {
    const ivkDictionary: Map<string, FunctionalGroup> = new Map<string, FunctionalGroup>();
    fgDataArray?.forEach((functionalGroupObj) => {
      const existingFgData = ivkDictionary.get(functionalGroupObj.IVK);
      if (!existingFgData) {
        ivkDictionary.set(functionalGroupObj.IVK, { ...functionalGroupObj, COUNT: 1 });
        return;
      }
      const ivkCount = (existingFgData.COUNT ?? 0) + 1;
      let workSPC = functionalGroupObj.WORK * functionalGroupObj.SPC;
      let travelSPC = functionalGroupObj.TRAVEL * functionalGroupObj.SPC;
      let priceSPC = functionalGroupObj.PRICE * functionalGroupObj.SPC;
      let mtbfSPC = functionalGroupObj.MTBF * functionalGroupObj.SPC;
      let SPC = functionalGroupObj.SPC;

      if (ivkCount == 2) {
        workSPC += existingFgData.WORK * existingFgData.SPC;
        travelSPC += existingFgData.TRAVEL * existingFgData.SPC;
        priceSPC += existingFgData.PRICE * existingFgData.SPC;
        mtbfSPC += existingFgData.MTBF * existingFgData.SPC;
        SPC += existingFgData.SPC;
      } else {
        workSPC += existingFgData.WORK;
        travelSPC += existingFgData.TRAVEL;
        priceSPC += existingFgData.PRICE;
        mtbfSPC += existingFgData.MTBF;
        SPC += existingFgData.SUMSPC ?? existingFgData.SPC;
      }

      ivkDictionary.set(functionalGroupObj.IVK, {
        ...functionalGroupObj,
        WORK: workSPC,
        TRAVEL: travelSPC,
        PRICE: priceSPC,
        MTBF: mtbfSPC,
        SUMSPC: SPC,
        COUNT: ivkCount,
      });
    });
    return Array.from(ivkDictionary.values());
  }

  updateCostCalculations(uniqueFgDetails: FunctionalGroup[], fgName: string) {
    let calculatedFgDetails: FunctionalGroupData[] = [];
    uniqueFgDetails.forEach((item) => {
      const existingFGIdx = this.savedReferenceSystemData[fgName.toUpperCase()]?.findIndex(
        (fg) => fg.FG === item.FG && fg.IVK === item.IVK
      );
      const existingFG =
        existingFGIdx > -1
          ? this.savedReferenceSystemData[fgName.toUpperCase()][existingFGIdx]
          : undefined;
      if (existingFG) {
        const updatedItem = this.updateFGItem(item, existingFG);
        calculatedFgDetails.push(updatedItem);
      } else {
        const newItem = this.createNewFgItem(item);
        calculatedFgDetails.push(newItem);
      }
    });
    return calculatedFgDetails;
  }

  updateInfluenceCalculations(calculatedFgDetails: FunctionalGroupData[]) {
    const totalCostPerPartRef = calculatedFgDetails.reduce((prev, curr) => prev + curr.COSTPERPARTREFERENCE, 0);
    const totalCostPerPartTarget = calculatedFgDetails.reduce((prev, curr) => prev + curr.COSTPERPARTTARGET, 0);
    calculatedFgDetails.forEach((item) => {
      const costPerPartRefPercentage = totalCostPerPartRef === 0 ? 0 :(item.COSTPERPARTREFERENCE / totalCostPerPartRef) * 100;
      item.INFLUENCE = costPerPartRefPercentage;

      const costPerPartTarget = item.COSTPERPARTTARGET ?? item.COSTPERPARTREFERENCE;
      const costPerPartTargetPercentage = totalCostPerPartTarget === 0 ? 0 :(costPerPartTarget / totalCostPerPartTarget) * 100;
      item.INFLUENCETARGET = costPerPartTargetPercentage;

      item.TOTALCOSTPERPARTREFORFG = totalCostPerPartRef;
      item.TOTALCOSTPERPARTTARGETORFG = totalCostPerPartTarget;
    });
    return calculatedFgDetails;
  }

  saveUpdatedFGToDB(itemsToSave: FunctionalGroupData[]) {
    this.isLoading = true;
    const userDataMappedArray = this.mapFormValuesToUserData(itemsToSave);
    this.sqlServiceService.addRecord3(userDataMappedArray).subscribe(
      () => {
        this.updatedReferenceSystemData = [];
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        console.error('Data is not saved. Error - ', error);
      }
    );
  }

  toggleFormState(showError: boolean, showFunctionalGroup: boolean, isSubmitted: boolean): void {
    this.showerror = showError;
    this.isFunctionalGroupInputData = showFunctionalGroup;
    this.isReferenceSystemDataSubmitted = isSubmitted;
  }

  cancelToggle() {
    this.toggleFormState(false, true, false);
  }

  confirmToggle() {
    this.toggleFormState(false, false, false);
    if (this.onConfirmNextStep == 'previousPage') {
      this.updateCurrentStep.emit(true);
    } else if (this.onConfirmNextStep == 'showAll') {
      this.receiveDataFromChild('showAll');
    } else {
      this.showFGData({ id: this.activeFGName, cardId: this.showFGDataCardId });
    }
  }

  toggleFGPartsCardsClick(cardId: number): void {
    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
    } else {
      if (this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]) {
        this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]['isSelected'] = false;
      }
      this.selectedFGPartsCardsId = cardId;
      if (this.selectedUniqueFGDataArray[cardId]) {
        this.selectedUniqueFGDataArray[cardId]['isSelected'] = true;
        this.selectedUniqueFGDataArray[cardId]['REFCOST'] =
          this.fgInfoArray[0]?.TOTALCOSTPERPARTREFORFG;
        this.selectedUniqueFGDataArray[cardId]['TARGETCOST'] =
          this.fgInfoArray[0]?.TOTALCOSTPERPARTTARGETORFG;
        this.searchBarComponent?.clearSearch();
      }
    }
  }

  createFGGroup(data: any, isPartAdded: boolean = false): FormGroup {
    return this.formBuilder.group({
      FG: [data.FG],
      IVK: [data.IVK],
      MATERIAL: [data.MATERIAL],
      TAKERATEREFERENCE: [
        { value: data.TAKERATEREFERENCE, disabled: this.isReadOnly || isPartAdded },
        Validators.required,
      ],
      TAKERATETARGET: [
        { value: data.TAKERATETARGET, disabled: this.isReadOnly },
        Validators.required,
      ],
      WORK: [data.WORK],
      WORKTARGET: [{ value: data.WORKTARGET, disabled: this.isReadOnly }, Validators.required],
      TRAVEL: [data.TRAVEL],
      TRAVELTARGET: [{ value: data.TRAVELTARGET, disabled: this.isReadOnly }, Validators.required],
      PRICE: [data.PRICE],
      PRICETARGET: [{ value: data.PRICETARGET, disabled: this.isReadOnly }, Validators.required],
      MTBFDEFAULT: [data.MTBF],
      MTBF: [data.MTBF],
      MTBFTARGET: [{ value: data.MTBFTARGET, disabled: this.isReadOnly }, Validators.required],
      COSTPERPARTREFERENCE: [data.COSTPERPARTREFERENCE, Validators.required],
      COSTPERPARTTARGET: [data.COSTPERPARTTARGET, Validators.required],
      INFLUENCE: [data.INFLUENCE],
      INFLUENCETARGET: [data.INFLUENCETARGET, Validators.required],
      SYSTEMATID: [data.SYSTEMATID],
      SLPNAME: [data.SLPNAME],
      isNew: [data.isNew],
      ADDEDPART: [data.ADDEDPART],
    });
  }

  populateTableData(data: any[]) {
    // Clear the form array before adding new data on Functional Group Selection
    const fgInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    fgInfoArray.clear();
    data.forEach((item) => {
      if (item.ADDEDPART === 1) {
        fgInfoArray.push(this.createFGGroup(item, true));
      } else {
        fgInfoArray.push(this.createFGGroup(item));
      }
    });
  }

  async setSumIbData(matid: string) {
    let sumIB = 0;
    if (this.savedSumIBDataCache.has(matid)) {
      sumIB = this.savedSumIBDataCache.get(matid) ?? 0;
    } else {
      sumIB = await lastValueFrom(this.sqlServiceService.getSumIB(matid));
      this.savedSumIBDataCache.set(matid, sumIB);
    }
    return sumIB;
  }

  updateFgInfoArray(item: any) {
    const existingFGIdx = this.fgInfoArray.findIndex(
      (fg) => fg.FG === item.FG && fg.IVK === item.IVK
    );
    const existingFG = existingFGIdx > -1 ? this.fgInfoArray[existingFGIdx] : undefined;
    if (!existingFG) {
      const newItem = this.createNewFgItem(item);
      this.totalcostPerPartRef += newItem.COSTPERPARTREFERENCE;
      this.totalcostPerPartTarget += newItem.COSTPERPARTTARGET;
      this.fgInfoArray.push(newItem);
    } else {
      const updatedItem = this.updateFGItem(item, existingFG);
      this.totalcostPerPartRef += updatedItem.COSTPERPARTREFERENCE;
      this.totalcostPerPartTarget += updatedItem.COSTPERPARTTARGET;
      this.fgInfoArray[existingFGIdx] = updatedItem;
    }
  }

  updateFGItem(newItem: FunctionalGroup, existingFG: FunctionalGroupData) {
    let {
      WORK: workValue = 0,
      TRAVEL: travelValue = 0,
      PRICE: priceValue = 0,
      MTBF: mtbfValue = 0,
      SUMSPC: sumSpcValue = 1,
      COUNT: ivkCount = 1,
    } = newItem;

    const workTravelPartsSum =
      workValue * this.slpHourRate + travelValue * this.slpHourRate + priceValue;

    if (ivkCount > 1) {
      workValue = workValue / sumSpcValue;
      travelValue = travelValue / sumSpcValue;
      priceValue = priceValue / sumSpcValue;
      mtbfValue = mtbfValue / sumSpcValue;
    }

    let mtbfTargetValue = existingFG.MTBFTARGET && existingFG.MTBFTARGET !== 0 ? existingFG.MTBFTARGET : mtbfValue;

    let updatedItem: FunctionalGroupData = {
      FG: newItem.FG,
      IVK: newItem.IVK,
      MATERIAL: newItem.MATERIAL,
      TAKERATEREFERENCE: existingFG.TAKERATEREFERENCE,
      TAKERATETARGET: existingFG.TAKERATETARGET,
      WORK:workValue,
      WORKTARGET: existingFG.WORKTARGET === 0 ? null : existingFG.WORKTARGET,
      TRAVEL: travelValue,
      TRAVELTARGET: existingFG.TRAVELTARGET === 0 ? null : existingFG.TRAVELTARGET,
      PRICE: priceValue,
      PRICETARGET: existingFG.PRICETARGET === 0 ? null : existingFG.PRICETARGET,
      MTBFDEFAULT: mtbfValue,
      MTBF: mtbfValue,
      MTBFTARGET: existingFG.MTBFTARGET === 0 ? null : existingFG.MTBFTARGET,
      SPC: newItem.SPC,
      SUMSPC: newItem.SPC,
      COSTPERPARTREFERENCE: mtbfTargetValue === 0 ? 0 : (workTravelPartsSum / mtbfTargetValue),
      COSTPERPARTTARGET: mtbfTargetValue === 0 ? 0 : (workTravelPartsSum / mtbfTargetValue),
      INFLUENCE: 0,
      INFLUENCETARGET: 0,
      SLPNAME: this.slpName,
      TOTALCOSTPERPARTREFORFG: 0,
      TOTALCOSTPERPARTTARGETORFG: 0,
      COUNT: newItem.COUNT ?? 0,
      ADDEDPART: 0,
    };

    const takeRateTargetValue = updatedItem.TAKERATETARGET / 100;
    const takeRateRefValue = updatedItem.TAKERATEREFERENCE / 100;

    const workTargetValue = updatedItem.WORKTARGET ?? 0;
    const workRefValue = updatedItem.WORK;
    const work = workTargetValue != 0 ? workTargetValue : workRefValue;

    const travelTargetValue = updatedItem.TRAVELTARGET ?? 0;
    const travelRefValue = updatedItem.TRAVEL;
    const travel = travelTargetValue != 0 ? travelTargetValue : travelRefValue;

    const priceTargetValue = updatedItem.PRICETARGET ?? 0;
    const priceRefValue = updatedItem.PRICE;
    const price = priceTargetValue != 0 ? priceTargetValue : priceRefValue;

    const mtbfTarValue = updatedItem.MTBFTARGET ?? 0;
    const mtbfRefValue = updatedItem.MTBF;
    const mtbf = mtbfTarValue != 0 ? mtbfTarValue : mtbfRefValue;

    const newCostPerPartTarget = this.calculateCostPerPartTarget(
      work,
      travel,
      price,
      mtbf,
      takeRateTargetValue,
      takeRateRefValue
    );

    updatedItem.COSTPERPARTTARGET = newCostPerPartTarget;

    return updatedItem;
  }

  createNewFgItem(item: any): FunctionalGroupData {
    let {
      WORK: workValue = 0,
      TRAVEL: travelValue = 0,
      PRICE: priceValue = 0,
      MTBF: mtbfValue = 0,
      SUMSPC: sumSpcValue = 1,
      COUNT: ivkCount = 1,
    } = item;

    const workTravelPartsSum =
      workValue * this.slpHourRate + travelValue * this.slpHourRate + priceValue;

    if (ivkCount > 1) {
      workValue = workValue / sumSpcValue;
      travelValue = travelValue / sumSpcValue;
      priceValue = priceValue / sumSpcValue;
      mtbfValue = mtbfValue / sumSpcValue;
    }

    return {
      FG: item.FG,
      IVK: item.IVK,
      MATERIAL: item.MATERIAL,
      TAKERATEREFERENCE: 100,
      TAKERATETARGET: 100,
      WORK: workValue,
      WORKTARGET: null,
      TRAVEL: travelValue,
      TRAVELTARGET: null,
      PRICE: priceValue,
      PRICETARGET: null,
      MTBFDEFAULT: mtbfValue,
      MTBF: mtbfValue, // TakeRateReference% is 1 as Initially TakeRateReference is 100 so TakeRateReference% is 1
      MTBFTARGET: null,
      SPC: item.SPC,
      SUMSPC: item.SPC,
      COSTPERPARTREFERENCE: workTravelPartsSum / item.MTBF,
      COSTPERPARTTARGET: workTravelPartsSum / item.MTBF, // this.takerateTar / this.takerateRef is 1 as Initially TakeRateReference is 100 , thus its  is 1 and not multiplied
      INFLUENCE: 0,
      INFLUENCETARGET: 0,
      SLPNAME: this.slpName,
      TOTALCOSTPERPARTREFORFG: 0,
      TOTALCOSTPERPARTTARGETORFG: 0,
      COUNT: item.COUNT,
      ADDEDPART: 0,
    };
  }

  showFGData(selectedFGCardData: { id: string, cardId: number }) {
    this.FGTableSpinner = true;
    this.displayAll = false;
    const { id, cardId } = selectedFGCardData;
    this.activeFGName = id;
    this.showFGDataCardId = cardId;

    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
      this.onConfirmNextStep = 'showCard';
      this.FGTableSpinner = false
      return;
    }

    this.isInvalidIVKError = false;
    this.isNewPartAdded = false;

    this.fgInfoArray = this.fgPartsDetails.get(this.activeFGName) ?? [];

    this.updateFGInfoArrayAll();

    this.populateTableData(this.fgInfoArray);
    const fgInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    this.filteredFGInfoArray = fgInfoArray.controls;
    this.toggleFGPartsCardsClick(cardId);

    this.isReferenceSystemDataSubmitted = false;

    this.calculateSelectedCardFGCost();
    this.calculateAndUpdateSystemCost();

    this.isReferenceSystemDataSubmitted = false;
    this.FGTableSpinner = false
  }

  updateFGInfoArrayAll() {
    this.fgInfoArray?.forEach((fgInfo) => {
      const existingFGIdx = this.fgInfoArrayAll.findIndex(
        (fg) => fg.FG === fgInfo.FG && fg.IVK === fgInfo.IVK
      );
      const existingFG = existingFGIdx > -1 ? this.fgInfoArrayAll[existingFGIdx] : undefined;
      if (!existingFG) {
        this.fgInfoArrayAll.push(fgInfo);
      } else {
        this.fgInfoArrayAll[existingFGIdx] = fgInfo;
      }
    });
  }

  getAllSelectedFGInfoArray() {
    let totalcostPerPartRef = this.fgInfoArrayAll.reduce(
      (ref, fg) => ref + fg.COSTPERPARTREFERENCE,
      0
    );
    let totalcostPerPartTarget = this.fgInfoArrayAll.reduce(
      (ref, fg) => ref + fg.COSTPERPARTTARGET,
      0
    );

    this.fgInfoArrayAll.forEach((item) => {
      const costPerPartRefPercentage = (item.COSTPERPARTREFERENCE / totalcostPerPartRef) * 100;
      item.INFLUENCE = costPerPartRefPercentage;

      const costPerPartTarget = item.COSTPERPARTTARGET ?? item.COSTPERPARTREFERENCE;
      const costPerPartTargetPercentage = (costPerPartTarget / totalcostPerPartTarget) * 100;
      item.INFLUENCETARGET = costPerPartTargetPercentage;

      item.TOTALCOSTPERPARTREFORFG = totalcostPerPartRef;
      item.TOTALCOSTPERPARTTARGETORFG = totalcostPerPartTarget;
    });
  }

  receiveDataFromChild(data: string) {
    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
      this.onConfirmNextStep = 'showAll';
      return;
    }
    if (data == 'showAll') {
      this.getAllSelectedFGInfoArray();
      this.displayAll = true;
      this.isInvalidIVKError = false;
      this.isNewPartAdded = false;
    }
  }

  getFormattedCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  convertToValidValue(value: any): any {
    if (value === '' || value === null || value === undefined) {
      return 0;
    }
    return Number(value);
  }

  mapFormValuesToUserData(fgInfoArray: FunctionalGroupData[]): UserData[] {
    return fgInfoArray.map((item) => ({
      SLPID: this.slpDetailsCardData.slpId,
      MATNAME: item.MATERIAL,
      FUNCTIONALGROUP: item.FG,
      IVK: item.IVK,
      SLPNAME: this.slpName,
      TAKERATEREFERENCE: item.TAKERATEREFERENCE,
      TAKERATETARGET: item.TAKERATETARGET,
      WORKREFERENCE: item.WORK,
      WORKTARGET: this.convertToValidValue(item.WORKTARGET),
      TRAVELREFERENCE: item.TRAVEL,
      TRAVELTARGET: this.convertToValidValue(item.TRAVELTARGET),
      PRICEREFERENCE: item.PRICE,
      PRICETARGET: this.convertToValidValue(item.PRICETARGET),
      MTBFREFERENCE: item.MTBF,
      MTBFTARGET: this.convertToValidValue(item.MTBFTARGET),
      COSTPERPARTREFERENCE: item.COSTPERPARTREFERENCE,
      COSTPERPARTTARGET: item.COSTPERPARTTARGET,
      INFLUENCEREFERENCE: item.INFLUENCE,
      INFLUENCETARGET: item.INFLUENCETARGET,
      CREATEDBY: this.authService.getAccount()?.username.toString(),
      CREATEDDATE: this.getFormattedCurrentDate(),
      ADDEDPART: item.ADDEDPART,
      ID: 0,
    }));
  }

  ReferencePageOnSubmit() {
    // Extract form values and map them to newArray
    this.fgInfoArray = this.fgForm.get('FGInfoArray')?.getRawValue();
    this.updateFGInfoArrayAll();
    let newParts = this.fgInfoArray.filter((fg) => fg.isNew === true);
    newParts?.forEach((newPart: FunctionalGroupData) => {
      this.allIVKList.add(newPart.IVK.trim());
      this.createSlpFormService.updateAllIvksList(this.allIVKList);
    });
    this.fgPartsDetails.set(this.activeFGName, this.fgInfoArray);
    const userDataMappedArray = this.mapFormValuesToUserData(this.fgInfoArray);
    this.sqlServiceService.addRecord3(userDataMappedArray).subscribe(() => {
      this.isFunctionalGroupInputData = false;
      this.isReferenceSystemDataSubmitted = false;
      this.showCreateSlpSuccessDialog = true;
      this.isNewPartAdded = false;
    });

    this.calculateSelectedCardFGCost();
    this.calculateAndUpdateSystemCost();
  }

  validateInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // Regex pattern to allow numbers with up to 5 decimal places
    const pattern = /^\d+(\.\d{1,5})?$/;

    // Check if the input value matches the pattern
    if (!pattern.test(value)) {
      // If the value doesn't match, show an alert or error message
      inputElement.setCustomValidity('Please enter a valid number with up to 5 decimal places.');
    } else {
      // If the value is valid, clear the custom validity message
      inputElement.setCustomValidity('');
    }

    // Report validity for real-time validation feedback
    inputElement.reportValidity();
  }

  get areTakeRateFieldsInvalid(): boolean {
    const fgInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    return fgInfoArray.controls.some((group) => {
      if (group instanceof FormGroup) {
        const takeRateReference = group.get('TAKERATEREFERENCE');
        const takeRateTarget = group.get('TAKERATETARGET');
        return takeRateReference?.invalid || takeRateTarget?.invalid;
      }
      return false;
    });
  }

  get isNewPartInvalid() {
    const fgInfoArray = this.fgForm.get('FGInfoArray')?.getRawValue();
    let newParts = fgInfoArray.filter((fg: FunctionalGroupData) => fg.isNew === true);
    let isInvalid = false;
    newParts?.every((newPart: FunctionalGroupData) => {
      isInvalid = newPart.IVK.trim().length === 0 || newPart.MATERIAL.trim().length === 0;
      return !isInvalid;
    });
    return isInvalid;
  }

  closeCreateSlpDialog() {
    this.showCreateSlpSuccessDialog = false;
  }

  calculateCostPerPartTarget(
    workValue: number,
    travelValue: number,
    priceValue: number,
    mtbfValue: number,
    takeRateTargetValue: number,
    takeRateRefValue: number
  ): number {
    if (mtbfValue == 0) {
      return 0;
    }
    const takeRateRef = takeRateRefValue === 0 ? 1 : takeRateRefValue;
    const workTravelPartsSum =
      workValue * this.slpHourRate + travelValue * this.slpHourRate + priceValue;
    const costPerPartTarget =
      (workTravelPartsSum / mtbfValue) * (takeRateTargetValue / takeRateRef);
    return costPerPartTarget;
  }

  getControlValue(control: any): number {
    const parsedValue = parseFloat(control?.value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  onFGInputChange(index: number): void {
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const takeRateTargetValue = this.getControlValue(fgGroup.get('TAKERATETARGET')) / 100;
    const takeRateRefValue = this.getControlValue(fgGroup.get('TAKERATEREFERENCE')) / 100;
    const workTargetValue = this.getControlValue(fgGroup.get('WORKTARGET'));
    const workRefValue = this.getControlValue(fgGroup.get('WORK'));
    const workValue = workTargetValue != 0 ? workTargetValue : workRefValue;

    const travelTargetValue = this.getControlValue(fgGroup.get('TRAVELTARGET'));
    const travelRefValue = this.getControlValue(fgGroup.get('TRAVEL'));
    const travelValue = travelTargetValue != 0 ? travelTargetValue : travelRefValue;

    const priceTargetValue = this.getControlValue(fgGroup.get('PRICETARGET'));
    const priceRefValue = this.getControlValue(fgGroup.get('PRICE'));
    const priceValue = priceTargetValue != 0 ? priceTargetValue : priceRefValue;

    const mtbfTarValue = this.getControlValue(fgGroup.get('MTBFTARGET'));
    const mtbfRefValue = this.getControlValue(fgGroup.get('MTBF'));
    const mtbfValue = mtbfTarValue != 0 ? mtbfTarValue : mtbfRefValue;

    const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

    const newCostPerPartTarget = this.calculateCostPerPartTarget(
      workValue,
      travelValue,
      priceValue,
      mtbfValue,
      takeRateTargetValue,
      takeRateRefValue
    );
    fgGroup.patchValue({ COSTPERPARTTARGET: newCostPerPartTarget });

    this.updateInfluenceTargetsOnTakeRateTarChange(costPerPartTargetValue, index);
  }

  updateInfluenceTargetsOnTakeRateRefChange(
    previousCostPerPartReference: number,
    previousCostPerPartTarget: number,
    index: number
  ): void {
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const newCostPerPartRefValue = this.getControlValue(fgGroup.get('COSTPERPARTREFERENCE'));
    const costPerPartRefDifference = newCostPerPartRefValue - previousCostPerPartReference;
    this.totalcostPerPartRef += costPerPartRefDifference;

    const newCostPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));
    const costPerPartTargetDifference = newCostPerPartTargetValue - previousCostPerPartTarget;
    this.totalcostPerPartTarget += costPerPartTargetDifference;

    this.FGInfoArray.controls.forEach((fgGroup) => {
      const costPerPartRefValue = this.getControlValue(fgGroup.get('COSTPERPARTREFERENCE'));
      const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

      const newInlfuenceRef = (costPerPartRefValue / this.totalcostPerPartRef) * 100;

      const costPerPartTarget = costPerPartTargetValue ?? costPerPartRefValue;
      const costPerPartTargetPercentage = (costPerPartTarget / this.totalcostPerPartTarget) * 100;
      const newInlfuenceTar = costPerPartTargetPercentage;

      fgGroup.patchValue({ INFLUENCE: newInlfuenceRef, INFLUENCETARGET: newInlfuenceTar });
    });
  }

  updateInfluenceTargetsOnTakeRateTarChange(
    previousCostPerPartTarget: number,
    index: number
  ): void {
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const newCostPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));
    const costPerPartTargetDifference = newCostPerPartTargetValue - previousCostPerPartTarget;
    this.totalcostPerPartTarget += costPerPartTargetDifference;

    this.FGInfoArray.controls.forEach((fgGroup) => {
      const costPerPartRefValue = this.getControlValue(fgGroup.get('COSTPERPARTREFERENCE'));
      const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

      const costPerPartTarget = costPerPartTargetValue ?? costPerPartRefValue;
      const costPerPartTargetPercentage = (costPerPartTarget / this.totalcostPerPartTarget) * 100;
      const newInlfuenceTar = costPerPartTargetPercentage;

      fgGroup.patchValue({ INFLUENCETARGET: newInlfuenceTar });
    });
  }

  onTakeRateRefChange(index: number): void {
    this.isFunctionalGroupInputData = true;
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const workRefValue = this.getControlValue(fgGroup.get('WORK'));
    const travelRefValue = this.getControlValue(fgGroup.get('TRAVEL'));
    const priceRefValue = this.getControlValue(fgGroup.get('PRICE'));
    const workTravelPartsSum =
      workRefValue * this.slpHourRate + travelRefValue * this.slpHourRate + priceRefValue;
    const costPerPartRefValue = this.getControlValue(fgGroup.get('COSTPERPARTREFERENCE'));
    const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

    let takeRateRefValue = this.getControlValue(fgGroup.get('TAKERATEREFERENCE')) / 100;
    const takeRateTarValue = this.getControlValue(fgGroup.get('TAKERATETARGET')) / 100;
    takeRateRefValue = takeRateRefValue === 0 ? 1 : takeRateRefValue;
    const mtbfRefValue = this.getControlValue(fgGroup.get('MTBFDEFAULT'));

    const newMTBF = mtbfRefValue * takeRateRefValue;
    let newCostPerPartRef = 0;
    let newCostPerPartTarget = 0;

    if (newMTBF && newMTBF > 0 && takeRateRefValue) {
      newCostPerPartRef = workTravelPartsSum / newMTBF;
      newCostPerPartTarget = (workTravelPartsSum / newMTBF) * (takeRateTarValue / takeRateRefValue);
    }

    fgGroup.patchValue({
      MTBF: newMTBF,
      COSTPERPARTREFERENCE: newCostPerPartRef,
      COSTPERPARTTARGET: newCostPerPartTarget,
    });

    this.updateInfluenceTargetsOnTakeRateRefChange(
      costPerPartRefValue,
      costPerPartTargetValue,
      index
    );
  }

  onTakeRateTargetChange(index: number): void {
    this.isFunctionalGroupInputData = true;
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const workTargetValue = this.getControlValue(fgGroup.get('WORKTARGET'));
    const workRefValue = this.getControlValue(fgGroup.get('WORK'));
    const workValue = workTargetValue != 0 ? workTargetValue : workRefValue;

    const travelTargetValue = this.getControlValue(fgGroup.get('TRAVELTARGET'));
    const travelRefValue = this.getControlValue(fgGroup.get('TRAVEL'));
    const travelValue = travelTargetValue != 0 ? travelTargetValue : travelRefValue;

    const priceTargetValue = this.getControlValue(fgGroup.get('PRICETARGET'));
    const priceRefValue = this.getControlValue(fgGroup.get('PRICE'));
    const priceValue = priceTargetValue != 0 ? priceTargetValue : priceRefValue;

    const mtbfTarValue = this.getControlValue(fgGroup.get('MTBFTARGET'));
    const mtbfRefValue = this.getControlValue(fgGroup.get('MTBFDEFAULT'));
    const mtbfValue = mtbfTarValue != 0 ? mtbfTarValue : mtbfRefValue;

    const workTravelPartsSum =
      workValue * this.slpHourRate + travelValue * this.slpHourRate + priceValue;

    const costPerPartRefValue = this.getControlValue(fgGroup.get('COSTPERPARTREFERENCE'));
    const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

    let takeRateRefValue = this.getControlValue(fgGroup.get('TAKERATEREFERENCE'));
    takeRateRefValue = takeRateRefValue === 0 ? 1 : takeRateRefValue / 100;
    const takeRateTarValue = this.getControlValue(fgGroup.get('TAKERATETARGET')) / 100;

    let newCostPerPartTarget = costPerPartRefValue;

    if (takeRateTarValue && mtbfValue && takeRateRefValue) {
      newCostPerPartTarget =
        (workTravelPartsSum / mtbfValue) * (takeRateTarValue / takeRateRefValue);
    }

    fgGroup.patchValue({ COSTPERPARTTARGET: newCostPerPartTarget });

    this.updateInfluenceTargetsOnTakeRateTarChange(costPerPartTargetValue, index);
  }

  FGInputDataUpdated(): void {
    this.isFunctionalGroupInputData = true;
  }

  get FGInfoArray() {
    return this.fgForm.get('FGInfoArray') as FormArray;
  }

  addNewPart() {
    const newRowData = {
      FG: this.activeFGName,
      IVK: '',
      MATERIAL: '',
      TAKERATEREFERENCE: 0,
      TAKERATETARGET: 100,
      WORK: 0,
      WORKTARGET: null,
      TRAVEL: 0,
      TRAVELTARGET: null,
      PRICE: 0,
      PRICETARGET: null,
      MTBF: 0,
      MTBFTARGET: null,
      COSTPERPARTREFERENCE: 0,
      COSTPERPARTTARGET: 0,
      INFLUENCE: 0,
      INFLUENCETARGET: 0,
      isNew: true,
      ADDEDPART: 1,
    };
    this.FGInfoArray.push(this.createFGGroup(newRowData, true));
    const fgInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    this.filteredFGInfoArray = fgInfoArray.controls;
    this.isNewPartAdded = true;
    this.isInvalidIVKError = true;
  }

  onPreviousButtonClick() {
    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
      this.onConfirmNextStep = 'previousPage';
    }
  }

  handleSearchResults(results: any[]): void {
    const FGInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    this.filteredFGInfoArray = FGInfoArray.controls.filter((control) =>
      results.some(
        (result) => result.MATERIAL === control.value.MATERIAL && result.IVK === control.value.IVK
      )
    );
  }

  calculateSelectedCardFGCost() {
    let cardCostPerPartRef = 0;
    let cardCostPerPartTar = 0;

    this.fgInfoArray.forEach((fgItem) => {
      let costRef = fgItem['COSTPERPARTREFERENCE'];
      let costTar = fgItem['COSTPERPARTTARGET'];
      cardCostPerPartRef += isNaN(costRef) ? 0 : costRef;
      cardCostPerPartTar += isNaN(costTar) ? 0 : costTar;
    });

    this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]['REFCOST'] = cardCostPerPartRef;
    this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]['TARGETCOST'] = cardCostPerPartTar;
  }

  calculateAndUpdateSystemCost() {
    this.totalSystemCostRef = 0;
    this.totalSystemCostTar = 0;
    this.selectedUniqueFGDataArray?.forEach((fgInfo) => {
      this.totalSystemCostRef += fgInfo.REFCOST;
      this.totalSystemCostTar += fgInfo.TARGETCOST;
    });
  }

  newPartIVKUpdated(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value.trim();

    if (value.length === 0) {
      this.isInvalidIVKError = true;
      inputElement.setCustomValidity('Material number is mandatory.');
    } else if (this.allIVKList.has(value)) {
      this.isInvalidIVKError = true;
      inputElement.setCustomValidity('Material number is already present in the project.');
    } else {
      this.isInvalidIVKError = false;
      inputElement.setCustomValidity('');
    }

    // Report validity for real-time validation feedback
    inputElement.reportValidity();
  }
}
