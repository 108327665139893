import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SqlServiceService } from 'src/app/core/sql-service.service';
import { SlpProjectData } from 'src/app/models';

@Component({
  selector: 'app-subscribed-slp',
  templateUrl: './subscribed-slp.component.html',
  styleUrls: ['./subscribed-slp.component.scss']
})
export class SubscribedSlpComponent {
  @Input() subscribedSlpData: SlpProjectData[] = []
  @Input() isLoading = true;
  @Output() unsubscribeCompleted = new EventEmitter<number>();

  constructor(
    private router: Router,
    private sqlServiceService: SqlServiceService,
  ){}
  
  navigateToPage(selectedSlpData: SlpProjectData) {
    this.router.navigate(['information'], { state: { savedSlpFormData: selectedSlpData } });
  }

  unsubscribe(slp : SlpProjectData){
    this.sqlServiceService.unsubscribeSLP(slp.SLPID).subscribe(() => {
      this.subscribedSlpData = this.subscribedSlpData.filter(x => x.SLPID !== slp.SLPID);
      this.unsubscribeCompleted.emit(slp.SLPID);
    });
  }

  currentSortColumn: string | null = null;
  isAscending: boolean = true;
  sortData(column: string) {
    // Toggle sorting direction
    if (this.currentSortColumn === column) {
      this.isAscending = !this.isAscending;
    } else {
      this.isAscending = true;
      this.currentSortColumn = column;
    }

    // Sort the data based on the column and direction
    this.subscribedSlpData.sort((a, b) => {
      const valueA = a[column as keyof SlpProjectData];
      const valueB = b[column as keyof SlpProjectData];
if(typeof valueA=='string')
{
  if (valueA.toLowerCase() < valueB.toLowerCase()) return this.isAscending ? -1 : 1;
  if (valueA.toLowerCase() > valueB.toLowerCase()) return this.isAscending ? 1 : -1;
}
else
{
  if (valueA < valueB) return this.isAscending ? -1 : 1;
  if (valueA > valueB) return this.isAscending ? 1 : -1;
}
      return 0;
    });
  }
}
  

