import { Component, Input,Output,EventEmitter } from '@angular/core';
import { SlpDetailsCard } from 'src/app/models';

@Component({
  selector: 'app-create-slp-project-info-card',
  templateUrl: './create-slp-project-info-card.component.html',
  styleUrls: ['./create-slp-project-info-card.component.scss']
})
export class CreateSlpProjectInfoCardComponent {
  @Input() slpDetailsCardData: SlpDetailsCard;
  @Input() isSelected: boolean;
  @Input() isreferenceSystemDisplay: boolean;
  @Input() totalSystemCostRef: number;
  @Input() totalSystemCostTar: number;
  @Output() dataEmitter = new EventEmitter<string>();

  send() {
    this.dataEmitter.emit("showAll");
  }
}
