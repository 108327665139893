import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgShuiModule } from '@shui/angular';

import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';

import 'src/app/shared/utils/ShuiComponent';

import { SharedModule } from 'src/app/shared/shared.module';
import { CreateSlpFormComponent } from './create-slp-form/create-slp-form.component';
import { SlpProjectDataFormComponent } from './components/slp-project-data-form/slp-project-data-form.component';
import { BaseSystemsFormComponent } from './components/base-systems-form2/base-systems-form2.component';
import { RefereneSystemsFormComponent } from './components/referene-systems-form/referene-systems-form.component';
import { CreateSlpProjectInfoCardComponent } from './components/create-slp-project-info-card/create-slp-project-info-card.component';
import { CreateSlpSelectionCardComponent } from './components/create-slp-selection-card/create-slp-selection-card.component';
import { FormatNumberPipe } from 'src/app/shared/pipes/format-number.pipe';

@NgModule({
  declarations: [
    CreateSlpFormComponent,
    SlpProjectDataFormComponent,
    CreateSlpFormComponent,
    BaseSystemsFormComponent,
    RefereneSystemsFormComponent,
    CreateSlpProjectInfoCardComponent,
    CreateSlpSelectionCardComponent,
    FormatNumberPipe
  ],
  imports: [
    CommonModule,
    NgShuiModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [FormatNumberPipe]
})
export class CreateSlpModule { }