<div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-bar">
        <sh-spinner label='Loading Reference System Data' *ngIf="isLoading"></sh-spinner>
    </div>
</div>

<div style="padding-top: 20px;"></div>
<sh-grid fit-content>
    <sh-card columns="3" col-m="12" col-s="12">
        <app-create-slp-project-info-card (dataEmitter)="receiveDataFromChild($event)"
            [slpDetailsCardData]='slpDetailsCardData' [slpDetailsCardData]='slpDetailsCardData'
            [isSelected]="displayAll" [totalSystemCostRef]="totalSystemCostRef"
            [totalSystemCostTar]="totalSystemCostTar" columns="12" col-m="12" col-s="12"
            style=" margin-top: 10px; margin-bottom: 20px;">
        </app-create-slp-project-info-card>

        <sh-text size='super-header' columns="12" col-m="12" col-s="12">Your Functional Group selections</sh-text>

        <app-create-slp-selection-card [selectedFGcard]='selectedFGcard' [isSelected]="!displayAll"
            *ngFor="let selectedFGcard of selectedUniqueFGDataArray" (FGCardClickedEvent)="showFGData($event)"
            style="height: 120px; margin-top: 10px; margin-bottom: 20px;">
        </app-create-slp-selection-card>

    </sh-card>
    <ng-container *ngIf="displayAll; else singleFG">
        <sh-card columns="9" col-m="12" col-s="12">
            <sh-grid fit-content>
                <sh-text size='super-header' columns="12" col-m="12" col-s="12">
                    All Service Parts Table
                </sh-text>
                <table id="sparePartTable" columns="12" col-m="12" col-s="12" style="width: 99%;">
                    <thead>
                        <tr>
                            <th style="width:4%">FG</th>
                            <th style="width:4%">Mat. ID</th>
                            <th style="width:10%">Mat. Text</th>
                            <th style="width:6%">Take Rate Ref. %</th>
                            <th style="width:6%">Take Rate Tar. %</th>
                            <th style="width:4%">Work Ref. (h)</th>
                            <th style="width:6%">Work Tar. (h)</th>
                            <th style="width:5%">Travel Ref. (h)</th>
                            <th style="width:4%">Travel Tar. (h)</th>
                            <th style="width:6%">Price (€)</th>
                            <th style="width:6%">Price Tar. (€)</th>
                            <th style="width:6%">MTBF Ref.</th>
                            <th style="width:6%">MTBF Tar.</th>
                            <th style="width:7%">Cost Per System Ref. (€)</th>
                            <th style="width:7%">Cost Per System Tar. (€)</th>
                            <th style="width:5%">Influence Ref. (%)</th>
                            <th style="width:7%">Influence Tar. (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let fg of fgInfoArrayAll">
                            <td>
                                {{ fg.FG }}
                            </td>
                            <td>
                                {{ fg.IVK }}
                            </td>
                            <td>
                                {{ fg.MATERIAL }}
                            </td>
                            <td>
                                {{fg.TAKERATEREFERENCE}}
                            </td>
                            <td>{{ fg.TAKERATETARGET }}</td>
                            <td>{{ fg.WORK | formatNumber }}</td>
                            <td>{{ fg.WORKTARGET | formatNumber }}</td>
                            <td>{{ fg.TRAVEL | formatNumber }}</td>
                            <td>{{ fg.TRAVELTARGET | formatNumber }}</td>
                            <td>{{ fg.PRICE | formatNumber }}</td>
                            <td>{{ fg.PRICETARGET | formatNumber }}</td>
                            <td>{{ fg.MTBF | formatNumber }}</td>
                            <td>{{ fg.MTBFTARGET | formatNumber }}</td>
                            <td>{{ fg.COSTPERPARTREFERENCE | formatNumber}}</td>
                            <td>{{ fg.COSTPERPARTTARGET | formatNumber }}</td>
                            <td>{{ fg.INFLUENCE | formatNumber }}</td>
                            <td>{{ fg.INFLUENCETARGET | formatNumber }}</td>
                        </tr>
                    </tbody>
                </table>
            </sh-grid>
        </sh-card>
    </ng-container>
    <ng-template #singleFG>
        <sh-card columns="9" col-m="12" col-s="12">
            <sh-spinner label='Loading' *ngIf="FGTableSpinner && selectedFGPartsCardsId !== -1"></sh-spinner>
            <sh-text *ngIf="!FGTableSpinner && selectedFGPartsCardsId === -1" size='super-header'
                style="color: #EC6602; text-align: center;">Please Select the Funcion Group to display Service Parts
                Data</sh-text>
            <sh-grid fit-content *ngIf="!FGTableSpinner && selectedFGPartsCardsId !== -1">
                <div columns="12" style="display: flex; flex-direction: row;">
                    <sh-text size='super-header' columns="12" col-m="12" col-s="12">
                        Service Parts Table
                    </sh-text>
                    <app-search-bar [searchAarray]="FGInfoArray.value" [searchKeys]="['IVK', 'MATERIAL']"
                        (searchResults)="handleSearchResults($event)" columns="5"
                        class="searchBarStyle"></app-search-bar>
                </div>

                <form [formGroup]="fgForm" (ngSubmit)="ReferencePageOnSubmit()" columns="12" col-m="12" col-s="12"
                    *ngIf="filteredFGInfoArray.length !== 0">
                    <table id="sparePartTable" columns="12" col-m="12" col-s="12" style="width: 99%;">
                        <thead>
                            <tr>
                                <th style="width:4%">FG</th>
                                <th style="width:4%">Mat. ID</th>
                                <th style="width:10%">Mat. Text</th>
                                <th style="width:6%">Take Rate Ref. %</th>
                                <th style="width:6%">Take Rate Tar. %</th>
                                <th style="width:4%">Work Ref. (h)</th>
                                <th style="width:6%">Work Tar. (h)</th>
                                <th style="width:5%">Travel Ref. (h)</th>
                                <th style="width:4%">Travel Tar. (h)</th>
                                <th style="width:6%">Price (€)</th>
                                <th style="width:6%">Price Tar. (€)</th>
                                <th style="width:6%">MTBF Ref.</th>
                                <th style="width:6%">MTBF Tar.</th>
                                <th style="width:7%">Cost Per System Ref. (€)</th>
                                <th style="width:7%">Cost Per System Tar. (€)</th>
                                <th style="width:5%">Influence Ref. (%)</th>
                                <th style="width:7%">Influence Tar. (%)</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="FGInfoArray">
                            <tr *ngFor="let fg of filteredFGInfoArray; let i = index" [formGroupName]="i">
                                <td>
                                    {{ fg.value.FG }}
                                </td>
                                <td>
                                    <ng-container *ngIf="fg.value.isNew; else nonEditableFG2">
                                        <input type="text" formControlName="IVK" class="small-input"
                                            (input)="newPartIVKUpdated($event)">
                                    </ng-container>
                                    <ng-template #nonEditableFG2>{{ fg.value.IVK }}</ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="fg.value.isNew; else nonEditableFG3">
                                        <input type="text" formControlName="MATERIAL" class="small-input">
                                    </ng-container>
                                    <ng-template #nonEditableFG3>{{ fg.value.MATERIAL }}</ng-template>
                                </td>
                                <td>
                                    <input type="number" formControlName="TAKERATEREFERENCE" class="small-input" min="0"
                                        max="300" step="0.01" pattern="^[0-9]{1,3}(\.\d{1,2})?$"
                                        title="Enter a number between 0 and 300, up to two decimal places"
                                        (input)="validateInput($event)" (keyup)="onTakeRateRefChange(i)"
                                         >
                                </td>
                                <td>
                                    <input type="number" formControlName="TAKERATETARGET" class="small-input" min="0"
                                        max="300" pattern="^[0-9]{1,3}(\.\d{1,2})?$"
                                        title="Enter a number between 0 and 300, up to two decimal places"
                                        (input)="validateInput($event)" (keyup)="onTakeRateTargetChange(i)"
                                         >
                                </td>
                                <td>{{ fg.value.WORK | formatNumber }}</td>
                                <td>
                                    <input type="text" formControlName="WORKTARGET" class="small-input"
                                        pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                        (input)="validateInput($event)" (keyup)="onFGInputChange(i)"
                                        (change)="FGInputDataUpdated()"  >
                                </td>
                                <td>{{ fg.value.TRAVEL | formatNumber }}</td>
                                <td>
                                    <input type="text" formControlName="TRAVELTARGET" class="small-input"
                                        pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                        (input)="validateInput($event)" (keyup)="onFGInputChange(i)"
                                        (change)="FGInputDataUpdated()"  >
                                </td>
                                <td>{{ fg.value.PRICE | formatNumber }}</td>
                                <td>
                                    <input type="text" formControlName="PRICETARGET" class="small-input"
                                        pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                        (input)="validateInput($event)" (keyup)="onFGInputChange(i)"
                                        (change)="FGInputDataUpdated()"  >
                                </td>
                                <td>{{ fg.value.MTBF | formatNumber }}</td>
                                <td>
                                    <input type="text" formControlName="MTBFTARGET" class="small-input"
                                        pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                        (keyup)="onFGInputChange(i)" (change)="FGInputDataUpdated()"
                                        (input)="validateInput($event)"  >
                                </td>
                                <td>{{ fg.value.COSTPERPARTREFERENCE | formatNumber}}</td>
                                <td>
                                    <input type="text" formControlName="COSTPERPARTTARGET" [readonly]="true"
                                        class="small-input">
                                </td>
                                <td>{{ fg.value.INFLUENCE | formatNumber }}</td>
                                <td>
                                    <input type="text" formControlName="INFLUENCETARGET" [readonly]="true"
                                        class="small-input">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="button-container">
                        <sh-button *ngIf="!isReadOnly" label="Save" (click)="ReferencePageOnSubmit()"
                            [disabled]="areTakeRateFieldsInvalid || isNewPartInvalid || isInvalidIVKError">
                        </sh-button>
                        <sh-button *ngIf="!isReadOnly" label="Add Parts" (click)="addNewPart()"
                            [disabled]="isNewPartAdded">
                        </sh-button>
                        </div>
                    </form>
                </sh-grid>
                <sh-spinner label='Loading' *ngIf="filteredFGInfoArray.length === 0 && selectedFGPartsCardsId !== -1"></sh-spinner>
        </sh-card>
    </ng-template>


    <div *ngIf="showCreateSlpSuccessDialog" class="custom-success-dialog">
        <div class="custom-dialog-content">
            <h1>Success</h1>
            <p>Record(s) have been successfully added!</p>
            <button class="dark-orange-button" (click)="closeCreateSlpDialog()">Ok</button>
        </div>
    </div>
    <div *ngIf="showerror" class="custom-success-dialog">
        <div class="custom-dialog-content">
            <h2>The Reference System values are not saved. </h2>
            <h3>Do you still want to continue ? </h3>
            <button class="dark-orange-button" (click)="confirmToggle()" style="margin-right:20px;">Yes</button>
            <button class="dark-orange-button" (click)="cancelToggle()">No</button>
        </div>
    </div>
</sh-grid>